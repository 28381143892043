import { Component }    from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'message-dialog',
  template: `<h1 mat-dialog-title>{{titulo}}</h1>
    <div mat-dialog-content>{{texto}}</div>
    <div mat-dialog-actions class="flex-container" fxLayout="row" fxLayoutAlign="center center">
      <button mat-button (click)="dialogRef.close()">Aceptar</button>
    </div>`
})

export class MessageDialog {
    public texto:string;
    public titulo:string;
    constructor(public dialogRef: MatDialogRef<MessageDialog>) {}
}

@Component({
  selector: 'confirm-dialog',
  template: `<h1 mat-dialog-title>{{titulo}}</h1>
    <div mat-dialog-content>{{texto}}</div>
    <div mat-dialog-actions class="flex-container" fxLayout="row" fxLayoutAlign="center center">
      <button mat-button (click)="dialogRef.close(true)">Aceptar</button>
      <button mat-button (click)="dialogRef.close(false)">Cancelar</button>
    </div>`
})

export class ConfirmDialog {
    public texto:string;
    public titulo:string;
    constructor(public dialogRef: MatDialogRef<ConfirmDialog>) {}
}

@Component({
  selector: 'loader-dialog',
  template: `
  <div fxLayout="column" fxLayoutAlign="center center">
        <div class="spinner" fxFlex="none" fxLayout="column" fxLayoutAlign="center center"><div class="spinner2" fxFlex="none"></div></div>
        <br>
        CARGANDO DATOS...
      </div>`,
  styles: [`
    .spinner {
      display: inline-block;
      margin: 0px;
      padding: 0px;
      width: 200px;
      height: 200px;
      background-color: rgb(1, 99, 226);
      border-radius: 50%;
      -webkit-animation: sk-rotateplane 2.5s infinite ease-in-out;
      animation: sk-rotateplane 2.5s infinite ease-in-out;
    }
    
    .spinner2 {
      display: inline-block;
      width: 200px;
      height: 200px;
      background-image: url("https://rest-cube.com/img/loader.png");
      background-repeat:no-repeat;
      background-size: 100% auto;
      background-position: center center;
      -webkit-animation: sk-rotateplane2 2.5s infinite ease-in-out;
      animation: sk-rotateplane2 2.5s infinite ease-in-out;
    }
    @keyframes sk-rotateplane {
      0% { 
        transform: scale(0.9,0.9);
        -webkit-transform: scale(0.9,0.9);
      } 25% { 
        transform: scale(1,1);
        -webkit-transform: scale(1,1);
      } 50% { 
        transform: scale(0.9,0.9);
        -webkit-transform: scale(0.9,0.9);
      } 75% { 
        transform: scale(1,1);
        -webkit-transform: scale(1,1);
      } 100% { 
        transform: scale(0.9,0.9);
        -webkit-transform: scale(0.9,0.9);
      }
    }
    
    @keyframes sk-rotateplane2 {
      0% { 
        transform: scale(1.3,1.3);
        -webkit-transform: scale(1.3,1.3);
      } 12% { 
        transform: scale(0.5,0.5);
        -webkit-transform: scale(0.5,0.5);
      } 25% { 
        transform: scale(1.3,1.3);
        -webkit-transform: scale(1.3,1.3);
      } 37% { 
        transform: scale(0.5,0.5);
        -webkit-transform: scale(0.5,0.5);
      } 50% { 
        transform: scale(1.3,1.3);
        -webkit-transform: scale(1.3,1.3);
      } 37% { 
        transform: scale(0.5,0.5);
        -webkit-transform: scale(0.5,0.5);
      } 50% { 
        transform: scale(1.3,1.3);
        -webkit-transform: scale(1.3,1.3);
      } 62% { 
        transform: scale(0.5,0.5);
        -webkit-transform: scale(0.5,0.5);
      } 75% { 
        transform: scale(1.3,1.3);
        -webkit-transform: scale(1.3,1.3);
      } 87% { 
        transform: scale(0.5,0.5);
        -webkit-transform: scale(0.5,0.5);
      } 100% { 
        transform: scale(1.3,1.3);
        -webkit-transform: scale(1.3,1.3);
      }
    }
  `]
})

export class LoaderDialog {
    constructor(public dialogRef: MatDialogRef<ConfirmDialog>) {}
}