<h1 mat-dialog-title style="text-align:center">#{{objFactura.cuenta.numero}}</h1>
<mat-dialog-content fxLayout="column" fxLayoutAlign="center">
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span fxFlex>Fecha:</span><span fxFlex>{{objFactura.cuenta.fecha}}</span>
  </div>
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span fxFlex>Cliente:</span><span fxFlex>{{objFactura.cuenta.titular}}</span>
  </div>
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span fxFlex>Mesero:</span><span fxFlex>{{objFactura.cuenta.mesero}}</span>
  </div>
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span fxFlex>Mesa:</span><span fxFlex>{{objFactura.cuenta.mesa}}</span>
  </div>
  <mat-nav-list>
    <h3>Productos</h3>
    <mat-list-item *ngFor="let producto of objFactura.cuenta.productos" fxLayout="row"  fxLayoutAlign="space-between center">
      <a mat-line fxFlex fxLayoutAlign="start center">{{producto.cantidad}}-{{producto.producto}}</a>
      <span fxFlex>{{producto.monto| dinero:'pesos'}}</span>
    </mat-list-item>
    <mat-list-item fxLayout="row"  fxLayoutAlign="space-between center" *ngIf="objFactura.cuenta.anexos.monto">
      <a mat-line fxFlex fxLayoutAlign="start center">{{objFactura.cuenta.anexos.producto}}</a>
      <span fxFlex>{{objFactura.cuenta.anexos.monto | dinero:'pesos'}}</span>
    </mat-list-item>
    <mat-list-item fxLayout="row"  fxLayoutAlign="space-between center">
      <a mat-line fxFlex fxLayoutAlign="start center">Total</a>
      <span fxFlex>{{objFactura.cuenta.total | dinero:'pesos'}}</span>
    </mat-list-item>
  </mat-nav-list>
  <mat-nav-list>
    <h3>Pagos</h3>
    <mat-list-item *ngFor="let pago of objFactura.cuenta.pagos" fxLayout="row"  fxLayoutAlign="space-between center">
      <a mat-line fxFlex fxLayoutAlign="start center">{{pago.tipoCobro}}</a>
      <span fxFlex>{{pago.monto| dinero:'pesos'}}</span>
    </mat-list-item>
  </mat-nav-list>
</mat-dialog-content>
<mat-dialog-actions class="flex-container" fxLayout="column" fxLayoutAlign="center center">
  <div class="flex-item">
    <button mat-raised-button color="accent" (click)="agregar()">Aceptar</button>
    <button mat-raised-button color="accent" (click)="dialogRef.close();">Cancelar</button>
  </div>
</mat-dialog-actions>