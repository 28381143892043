import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule,Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { FlexModule } from '@angular/flex-layout';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';

import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FacturasComponent } from './facturas/facturas.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { 
  BuscarPipe, 
  FiltrarPipe, 
  FiltrarAndNullPipe, 
  FiltrarNULLPipe, 
  FiltrarMenoresPipe, 
  FiltrarPaquetesPipe, 
  ExcluirPipe, 
  ExcluirNULLPipe, 
  ExcluirMatrizPipe, 
  RestarMatricesPipe, 
  FiltrarMatrizPipe, 
  FiltrarMatrizNULLPipe, 
  AgrupaVentaPipe, 
  AgrupaMateriaPrimaPipe, 
  Dinero,
  ReversePipe
} from './shared/filter';

import { Tool } from './shared/tool';
import { FacturasService } from './services/facturas.service';
import { FacturaDialog } from './facturas/factura.dialog';
import { MessageDialog, ConfirmDialog, LoaderDialog } from './shared/dialogs.component';

@NgModule({
  declarations: [
    AppComponent,
    FacturasComponent, 
    MessageDialog, 
    ConfirmDialog, 
    LoaderDialog, 
    FacturaDialog, 
    BuscarPipe, 
    FiltrarPipe, 
    FiltrarAndNullPipe, 
    FiltrarNULLPipe, 
    FiltrarMenoresPipe, 
    FiltrarPaquetesPipe, 
    ExcluirPipe, 
    ExcluirNULLPipe, 
    ExcluirMatrizPipe, 
    RestarMatricesPipe, 
    FiltrarMatrizPipe, 
    FiltrarMatrizNULLPipe, 
    AgrupaVentaPipe, 
    AgrupaMateriaPrimaPipe, 
    Dinero,
    ReversePipe
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule, 
    FormsModule,
    ReactiveFormsModule, 
    FlexModule, 
    MatAutocompleteModule, 
    MatDialogModule, 
    MatToolbarModule, 
    MatIconModule, 
    MatSliderModule,
    MatInputModule, 
    MatButtonModule, 
    MatListModule, 
    BrowserAnimationsModule
  ],
  providers: [
    Tool,
    FacturasService
  ],
	entryComponents: [ 
    MessageDialog, 
    ConfirmDialog, 
    FacturaDialog
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
