<div *ngIf="!objFactura.datosFactura.correo && !objFactura.comercio.idComercio" fxLayout="row" fxLayoutAlign="center center">
    <span fxFlex>El Link ha caducado</span>
</div>
<form *ngIf="!objFactura.datosFactura.correo && objFactura.comercio.idComercio&&!cliente.idCliente" #clienteForm="ngForm">
    <div fxLayout="column" fxLayoutAlign="center center">
        <img fxFlex src='https://rest-cube.com/img/icono.png'>
        <span fxFlex>Bien venido a la facturación Rest Cube</span>
        <img fxFlex [src]='objFactura.comercio.logo'>
        <span fxFlex>Ahora vamos a realizar su facturación para {{objFactura.comercio.nombre}}</span>
        <span fxFlex>Coloque su RFC y presione el botón con forma de lupa.</span>
    </div>
    <BR>
    <BR>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
        <mat-form-field fxFlex="none">
            <input matInput placeholder="RFC" #txtBuscarRFC [(ngModel)]="objFactura.cliente.RFC" (keyup.enter)="buscarCliente(txtBuscarRFC.value)"/>
            <mat-hint align="end" *ngIf="!txtBuscarRFC.valid">
                "El campo es requerido"
            </mat-hint>
        </mat-form-field>
        <button mat-button matSuffix mat-icon-button [disabled]="txtBuscarRFC.value==''" (click)="buscarCliente(txtBuscarRFC.value)">
            <mat-icon>search</mat-icon>
        </button>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" *ngIf="objFactura.cliente.idCliente">
        <div fxFlex="none">
            RFC: {{objFactura.cliente.RFC}}
        </div>
        <mat-form-field fxFlex="none">
            <input matInput [(ngModel)]="objFactura.cliente.nombre" placeholder="Razon Social" #txtNombre="ngModel" name="txtNombre" id="txtNombre" (change)="objFactura.cliente.nombre=eliminarDiacriticosEs(objFactura.cliente.nombre)" required/>
            <mat-hint align="end" *ngIf="!txtNombre.valid">
                El campo es requerido
            </mat-hint>
        </mat-form-field>
        <mat-form-field fxFlex="none">
            <input matInput type="email" [(ngModel)]="objFactura.cliente.correo" placeholder="Correo" #txtCorreo="ngModel" name="txtCorreo" id="txtCorreo" pattern="[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*" required/>
            <mat-hint align="end" *ngIf="!txtCorreo.valid">
                El campo es requerido
            </mat-hint>
        </mat-form-field>
        <mat-form-field fxFlex="none">
            <input matInput [(ngModel)]="objFactura.cliente.CP" placeholder="Codigo Postal" #txtCP="ngModel" name="txtCP" id="txtCP" required/>
            <mat-hint align="end" *ngIf="!txtCP.valid">
                El campo es requerido
            </mat-hint>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" *ngIf="objFactura.cliente.idCliente">
        <mat-form-field fxFlex>
            <input matInput #txtBuscarRegimenesFiscales #regimenFiscal="ngModel" placeholder="Regimen Fiscal" [matAutocomplete]="autoRegimenFiscal" [(ngModel)]="objFactura.cliente.regimenFiscal" name="regimenFiscal" required>
            <mat-autocomplete #autoRegimenFiscal="matAutocomplete" [displayWith]="displayFnRegimen">
                <mat-option *ngFor="let regimenFiscal of (RegimenesFiscales | buscar : 'regimenFiscal' : txtBuscarRegimenesFiscales.value)" [value]="regimenFiscal">
                    {{ regimenFiscal.regimenFiscal }}
                </mat-option>
            </mat-autocomplete>
            <mat-hint align="end" *ngIf="!regimenFiscal.valid">
                El campo es requerido
            </mat-hint>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="objFactura.cliente.idCliente">
        <button mat-raised-button fxFlex="none" color="accent" [disabled]="!clienteForm.valid" (click)="copiaCliente(objFactura.cliente)">siguiente</button>
    </div>
</form>
<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" *ngIf="!objFactura.datosFactura.correo && cliente.idCliente">
    <mat-form-field fxFlex="none">
        <input matInput placeholder="Numero de Ticket" #txtBuscarTicket/>
        <mat-hint align="end" *ngIf="!txtBuscarTicket.valid">
            El campo es requerido
        </mat-hint>
    </mat-form-field>
    <mat-form-field fxFlex="none">
        <input matInput placeholder="Referencia" #txtBuscarReferencia/>
        <mat-hint align="end" *ngIf="!txtBuscarTicket.valid">
            El campo es requerido
        </mat-hint>
    </mat-form-field>
    <button mat-button matSuffix mat-icon-button (click)="buscarTicket(idCuenta,txtBuscarTicket,txtBuscarReferencia)">
        <mat-icon>search</mat-icon>
    </button>
</div>
<table *ngIf="!objFactura.datosFactura.correo && objFactura.cuentas.length>0">
    <tr>
        <th colspan="5"><H1>Tickets a Facturar</H1></th>
    </tr>
    <tr>
        <th>#Ticket</th>
        <th>Fecha</th>
        <th>Cliente</th>
        <th>Total</th>
        <th>Eliminar</th>
    </tr>
    <tr *ngFor="let cuenta of objFactura.cuentas; let i = index">
        <td>{{cuenta.numero}}</td>
        <td>{{cuenta.fecha}}</td>
        <td>{{cuenta.titular}}</td>
        <td>{{cuenta.total | dinero:'pesos'}}</td>
        <td>
            <button mat-icon-button (click)="eliminar(i)">
                <mat-icon>cancel</mat-icon>
            </button>
        </td>
    </tr>
    <tr>
        <td colspan="3">
            TOTAL
        </td>
        <td>
            {{objFactura.total | dinero:'pesos'}}
        </td>
        <td></td>
    </tr>
</table>


<form *ngIf="!objFactura.datosFactura.correo && objFactura.cuentas.length>0" #facturasForm="ngForm" class="flex-container" fxLayout="column" fxLayoutAlign="center center">
    <mat-form-field fxFlex>
        <input matInput #txtBuscarUsos #Uso="ngModel" placeholder="Uso de CFDI" [matAutocomplete]="autoUso" [(ngModel)]="objFactura.factura.usoCFDI" name="Uso" required>
        <mat-autocomplete #autoUso="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let uso of (Usos | buscar : 'descripcion' : txtBuscarUsos.value)" [value]="uso">
                {{ uso.descripcion }}
            </mat-option>
        </mat-autocomplete>
        <mat-hint align="end" *ngIf="!Uso.valid">
            El campo es requerido
        </mat-hint>
    </mat-form-field>
    <button mat-raised-button fxFlex="none" color="accent" (click)="facturar(this.cliente)" [disabled]="!facturasForm.form.valid"><mat-icon>article</mat-icon>Facturar</button>
</form>

<div *ngIf="objFactura.datosFactura.correo && !objFactura.datosFactura.error" fxLayout="column" fxLayoutAlign="center center">
    <BR>
        <span fxFlex>Muchas gracias por haber utilizado la Facturación Rest-Cube, esperamos tu experiencia haya sido excelente</span>
    <BR>
        <span fxFlex>La factura se ha enviado al correo {{objFactura.datosFactura.correo}}</span>
    <BR>
        <span fxFlex>Descarga la factura en formato PDF <button mat-mini-fab (click)="tool.abrirURL(objFactura.datosFactura.PDF)"><mat-icon matTooltip="Descarga">file_download</mat-icon></button></span>
    <BR>
        <span fxFlex>Descarga el XML: <button mat-mini-fab (click)="tool.abrirURL(objFactura.datosFactura.XML)"><mat-icon matTooltip="Descarga">file_download</mat-icon></button></span>
    <BR>
        <span fxFlex>Hasta pronto</span>
        <img src='https://rest-cube.com/img/icono.png'>
</div>

<div *ngIf="objFactura.datosFactura.correo && objFactura.datosFactura.error" fxLayout="column" fxLayoutAlign="center center">
    <BR>
        <img fxFlex [src]='objFactura.comercio.logo'>
    <BR>
        <span fxFlex style="padding: 50px;text-align: center;">La configuración de {{objFactura.comercio.nombre}} no permite generar la factura en éste momento, porfavor comuníquese con un representante del mismo.</span>
    <BR>
        <span fxFlex *ngIf="objFactura.comercio.telefono">Teléfono: <a href="tel:+{{objFactura.comercio.telefono}}">{{objFactura.comercio.telefono}}</a> </span>
    <BR>
        <span fxFlex *ngIf="objFactura.comercio.email">O al correo electrónico: <a href="mailto:{{objFactura.comercio.email}}">{{objFactura.comercio.email}}</a></span>
</div>