import { Pipe, PipeTransform,Injectable } from '@angular/core';

@Pipe({
    name: 'buscar'
})
@Injectable()
export class BuscarPipe implements PipeTransform {
    transform(items: any[], field : string, value : string): any[] {
        if (!items){
            return [];
        }else if(value.length>0){
            //console.log(items.filter(row => (row[field].toLowerCase().indexOf(value.toLowerCase()))>=0));
            return items.filter(row => (row[field].toLowerCase().indexOf(value.toLowerCase()))>=0);
        }else{
            return items;
        }
    }
}

@Pipe({
    name: 'filtrar'
})
@Injectable()
export class FiltrarPipe implements PipeTransform {
    transform(items: any[], field:string, value): any[] {
        if (!items){
            return [];
        }else if(value!=null && value!=-1){
            return items.filter(row => (row[field]==value));
        }else{
            return items;
        }
    }
}

@Pipe({
    name: 'filtrarAndNull'
})
@Injectable()
export class FiltrarAndNullPipe implements PipeTransform {
    transform(items: any[], field:string, value): any[] {
        if (!items){
            return [];
        }else{
            return items.filter(row => (row[field]==value || row[field]==null));
        }
    }
}

@Pipe({
    name: 'filtrarNULL'
})
@Injectable()
export class FiltrarNULLPipe implements PipeTransform {
    transform(items: any[], field:string, value): any[] {
        if (!items){
            return [];
        }else{
            return items.filter(row => (row[field]==value));
        }
    }
}

@Pipe({
    name: 'filtrarMenores'
})
@Injectable()
export class FiltrarMenoresPipe implements PipeTransform {
    transform(items: any[], field:string, limite: number): any[] {
        if (!items){
            return [];
        }else if(limite!=null){
            return items.filter(row => (row[field]>=limite));
        }else{
            return items;
        }
    }
}

@Pipe({
    name: 'filtrarPaquetes'
})
@Injectable()
export class FiltrarPaquetesPipe implements PipeTransform {
    transform(items: any[], idMateriaPrima:number, idProveedor: number): any[] {
        if (!items){
            return [];
        }else if(idMateriaPrima!=null && idMateriaPrima!=-1){
            let res= JSON.parse(JSON.stringify(items.filter(row=>row['idMateriaPrima']==idMateriaPrima))).reduce((prev, cur)=> {
                if(!prev.length){
                    prev=[];
                }
                let temp = prev.filter(row=>row['idPaquete']==cur['idPaquete']);
                if(temp.length==0){
                    prev[prev.length]=cur;
                }else{
                    if(cur['idProveedor']==idProveedor){
                        temp[0].monto=cur.monto;
                    }
                }
                return prev;
              }, {});
            return res;
        }else{
            return items;
        }

    }
}

@Pipe({
    name: 'excluir'
})
@Injectable()
export class ExcluirPipe implements PipeTransform {
    transform(items: any[], field:string, value: number): any[] {
        if (!items){
            return [];
        }else if(value!=null){
            return items.filter(row => (row[field]!=value));
        }else{
            return items;
        }
    }
}

@Pipe({
    name: 'excluirNULL'
})
@Injectable()
export class ExcluirNULLPipe implements PipeTransform {
    transform(items: any[], field:string, value: number): any[] {
        if (!items){
            return [];
        }else{
            return items.filter(row => (row[field]!=value));
        }
    }
}

@Pipe({
    name: 'excluirMatriz'
})

@Injectable()
export class ExcluirMatrizPipe implements PipeTransform {
    transform(items: any[], excluye:any[], diaSemana:number): any[] {
        console.log(items);
        return items;
        /*if (!items){
            return [];
        }else if(!diaSemana||(!excluye&&excluye.length>0)){
            return items;
        }else{
            let temp=excluye.filter(row => (row['diaSemana']==diaSemana))
            return items.filter(row => (temp.filter(row2 => (row['idTarea']==row2['idTarea'])).length==0));
        }*/
    }
}

@Pipe({
    name: 'restarMatrices'
})

@Injectable()
export class RestarMatricesPipe implements PipeTransform {
    transform(items: any[], excluye:any[], campoFiltro:string, valor:number, campoExclusion:string): any[] {
        if (!items){
            return [];
        }else if(!excluye || valor==null || valor==-1){
            return items;
        }else{
            let temp=excluye.filter(row => (row[campoFiltro]==valor));
            return items.filter(row => (temp.filter(row2 => (row[campoExclusion]==row2[campoExclusion])).length==0));
        }
    }
}

@Pipe({
    name: 'filtrarMatriz'
})
@Injectable()
export class FiltrarMatrizPipe implements PipeTransform {
    
    transform(items: any[], field: number, arrayG:string): any[] {
        if (!items || !arrayG)
            return [];
        else
            return items.filter(row => this.buscaEnMatriz(row[field],arrayG));
    }

    buscaEnMatriz(valor,grupo){
        let matriz=grupo.split(",");
        let cont=0;
        while(cont<matriz.length && matriz[cont]!=valor){
            cont++;
        }
        return (matriz[cont]==valor&&valor!=null);
    }
}

@Pipe({
    name: 'filtrarNULLMatriz'
})
@Injectable()
export class FiltrarMatrizNULLPipe implements PipeTransform {
    
    transform(items: any[], field: number, arrayG:string): any[] {
        if (!items || !arrayG)
            return [];
        else
            return items.filter(row => this.buscaEnMatriz(row[field],arrayG));
    }

    buscaEnMatriz(valor,grupo){
        let matriz=grupo.split(",");
        let cont=0;
        while(cont<matriz.length && matriz[cont]!=valor){
            cont++;
        }
        return (matriz[cont]==valor);
    }
}

@Pipe({
    name: 'agrupaVenta'
})
@Injectable()
export class AgrupaVentaPipe implements PipeTransform {
    
    transform(itemsOrigin: any[]): any[] {
        if (!itemsOrigin){
            return [];
        }else if(itemsOrigin.length<=1)
            return itemsOrigin;
        else{
            //console.log(itemsOrigin);
            let items=this.clonaObjeto(itemsOrigin);
            return this.clonaObjeto(items.reduce((prev, cur)=> {
                if(!prev.length){
                    prev=[];
                }
                let temp = prev.filter(row => (row['estado']==cur['estado']&&row['idUsuarioPeticion']==cur['idUsuarioPeticion']&&row['producto']==cur['producto']&&row['variantes']==cur['variantes']&&row['monto']==cur['monto']&&row['modificada']==cur['modificada']));
                if(temp.length==0){
                    prev[prev.length]=cur;
                }else{
                    temp[0].cantidad=temp[0].cantidad+1;
                }
                return prev;
              }, {}));
            }
    }

    clonaObjeto(objeto){
        return JSON.parse(JSON.stringify(objeto));
    }

}

@Pipe({
    name: 'agrupaMateriaPrima'
})
@Injectable()
export class AgrupaMateriaPrimaPipe implements PipeTransform {
    
    transform(items: any[]): any[] {
        if (!items){
            return [];
        }else if(items.length<=1)
            return items;
        else{
            return items.reduce((prev, cur)=> {
                if(!prev.length){
                    prev=[];
                }
                let temp = prev.filter(row => (row['idMateriaPrima']==cur['idMateriaPrima']));
                if(temp.length==0){
                    prev[prev.length]=cur;
                }else{
                    temp[0].total=Number(temp[0].total) + Number(cur['total']);
                }
                return prev;
              }, {});
            }
    }

}

@Pipe({
    name: 'dinero'
})
@Injectable()
export class Dinero implements PipeTransform {
    transform(cantidad: number, conversion : string): any {  
        var matriz= (cantidad)?Array.from(cantidad.toFixed(2)):"0.00";
        var res="";
        for(let cont=matriz.length-1; cont>=0; cont--){
            if((matriz.length-cont-4)>0 && (matriz.length-cont-4)%3==0){
                res=","+res;
            }
            res=matriz[cont]+res;
        }
        switch(conversion){
            case "pesos":
                return "$"+res;
            case "euros":
                return "€"+res;
            default:
                return res;
        }
    }
}

@Pipe({
    name: 'reverse'
  })
  export class ReversePipe implements PipeTransform {
  
    transform(arr) {
      let copy = arr.slice();
      return copy.reverse();
  }
  }