
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

import { FacturasService } from '../services/facturas.service';

@Component({
    selector: 'factura-dialog',
    templateUrl: './factura.dialog.html'
  })
  
export class FacturaDialog implements OnDestroy {
    constructor(
        public dialogRef: MatDialogRef<FacturaDialog>, 
        public objFactura: FacturasService
    ) {}

    ngOnDestroy(){
        //console.log("Destruir");
    }
    
    agregar(){
        this.objFactura.cuentas.push(this.objFactura.cuenta);
        this.objFactura.total=0;
        this.objFactura.cuentas.forEach(cuenta=>this.objFactura.total+=cuenta.total);
        this.dialogRef.close();
    }

}