import { takeUntil } from 'rxjs/operators';
import { Injectable, Inject }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

import {MatDialog} from '@angular/material/dialog';

import {Tool} from '../shared/tool';

@Injectable({
    providedIn: 'root',
})
export class FacturasService {

    private ngUnsubscribe: Subject<any> = new Subject<any>();
    private Url =this.tool.Url()+"/FacturacionController";
    public comercio:any={};
    public cuenta:any={};
    public cuentas:any[]=[];
    public cliente:any={};
    public factura:any={usoCFDI:""};
    public datosFactura:any={};
    public total:number=0;
    
    constructor(
        private http: HttpClient,
        private tool: Tool,
        public dialog: MatDialog
    ) { }

    listar(id): void{
        this.tool.show();
        this.http.get(this.Url+"/"+id, this.tool.options())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (data:any) => {
                    if(data){console.log(data);
                        if(data.length>0 && data[0].MesFactura==data[0].MesActual && data[0].Facturar){
                            this.comercio=data[0];
                        }
                    }else{
                        this.tool.msgBox("Error", data.Error,this.dialog);
                    }
                },
                error=>{
                    this.tool.manejoError(error);
                    this.tool.hide();
                },
                ()=>this.tool.hide()
            );
    }
    
    buscarCliente(RFC,RegimenesFiscales){
        this.tool.show();
        return this.http.get(this.Url+"/buscarCliente/"+RFC, this.tool.options())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (data:any) => {
                    if(data){
                        if(data.length>0){
                            this.cliente=data[0];
                            this.cliente.regimenFiscal=RegimenesFiscales.find(regimenFiscal=>regimenFiscal.clave==this.cliente.regimenFiscal);
                        }else{
                            this.cliente={idCliente:-1,RFC:RFC,nombre:"",correo:""};
                        }
                        console.log(this.cliente);
                    }else{
                        this.tool.msgBox("Error", data.Error,this.dialog);
                    }
                },
                error=>{
                    this.tool.manejoError(error);
                    this.tool.hide();
                },
                ()=>this.tool.hide()
            );
    }

    buscarTicket(idCuenta,numero,referencia){
        this.tool.show();
        return this.http.post(this.Url+"/buscarTicket", {idCuenta:idCuenta,numero:numero,referencia:referencia}, this.tool.options())
            .pipe(takeUntil(this.ngUnsubscribe));
    }

    facturar(){
        this.tool.show();
        return this.http.post(this.Url+"/facturar", this.factura,this.tool.options())
            .pipe(takeUntil(this.ngUnsubscribe));
    }

}